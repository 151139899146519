import React from "react";

import Faq from "../Faq/Faq";
import classes from "./MasterfulMasonry.module.scss"
import image_01 from '../../../assets/img/ServicesPage/Blocks/image_04_01.png';
import image_02 from '../../../assets/img/ServicesPage/Blocks/image_04_02.png';

const tabs = [
    {
        title: 'Structural Masonry',
        text: 'Our skilled masons are experts in structural masonry, including brickwork and stonework. We repair and restore deteriorated walls, chimneys, and facades, ensuring your building remains stable and safe.',
    },
    {
        title: 'Decorative Masonry',
        text: 'Elevate your building\'s visual appeal with decorative masonry features.We create custom designs, intricate patterns, and artistic elements that enhance the architectural beauty of your property.',
    },
    {
        title: 'Concrete Repair',
        text: 'Our professionals address concrete issues such as cracks, spalling, and deterioration. We restore concrete surfaces to their original strength and appearance.',
    },
    {
        title: 'Tile Installation',
        text: 'Whether you desire new tiling for your floors, walls, or exteriors, our tile installation experts deliver precise, flawless workmanship. We offer an array of tile options to suit your style and preferences.',
    },
    {
        title: 'Tiling Renovations',
        text: 'Our tiling renovations breathe new life into outdated or damaged surfaces. From kitchen backsplashes to bathroom makeovers, we transform spaces with stunning tiling solutions.',
    },
    {
        title: 'Outdoor Tiling',
        text: 'Enhance your outdoor areas with durable and weather-resistant tiling. We install outdoor tiles for patios, decks, walkways, and pool areas.',
    },
];

const MasterfulMasonry = () => {
    return (
        <section className={[classes.blockWrap, classes.blockWrap_01].join(' ')}>
            <div className="container mt">
                <div className={classes.block}>
                    <h2 className={[classes.title, 'font-48'].join(' ')}>
                        Masterful Masonry<br />and Tiling Services
                    </h2>
                    <div className={classes.content}>
                        <div className={classes.txt}>
                            <h4 className={[classes.subtitle, 'font-20'].join(' ')}>
                                Elevating Your Building's Aesthetics and Durability
                            </h4>
                            <p className={[classes.text, 'font-16'].join(' ')}>
                                At Ard Altaamir, we are aware of the crucial part that masonry and tiling play in maintaining the structural integrity and aesthetic appeal of your structure.
                            </p>
                        </div>
                        <div className={classes.faqWrap}>
                            <Faq tabs={tabs} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={[classes.imageWrap, classes.imageWrapLeft, classes.imageWrap_02_01].join(' ')}>
                <img src={image_01} alt='' />
            </div>
            <div className={[classes.imageWrap, classes.imageWrapRight, classes.imageWrap_02_02].join(' ')}>
                <img src={image_02} alt='' />
            </div>
            <div className={[classes.mob, ""].join(" ")}>
                <img src={image_01} alt="" />
            </div>

        </section>
    );
};

export default MasterfulMasonry;
