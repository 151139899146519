import React, { useEffect, useState }  from "react";
import classes from './Vision.module.scss';
import icon_01 from '../../../assets/img/AboutPage/Vision/icon_01.png';
import icon_02 from '../../../assets/img/AboutPage/Vision/icon_02.png';
import icon_03 from '../../../assets/img/AboutPage/Vision/icon_03.png';
import icon_04 from '../../../assets/img/AboutPage/Vision/icon_04.png';
import icon_05 from '../../../assets/img/AboutPage/Vision/icon_05.png';
import icon_06 from '../../../assets/img/AboutPage/Vision/icon_06.png';
import icon_07 from '../../../assets/img/AboutPage/Vision/icon_07.png';
import icon_08 from '../../../assets/img/AboutPage/Vision/icon_08.png';
import arrow from '../../../assets/img/HomePage/WhyChoose/arrow.png';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Grid } from "swiper";
import "swiper/css";
import 'swiper/css/grid';

const cards = [
    {
        icon: icon_01,
        title: 'Excellence',
        text: 'We are dedicated to delivering services of the highest quality, setting the bar for excellence in every project we undertake.',
    },
    {
        icon: icon_02,
        title: 'Integrity',
        text: 'Our business is built on honesty, transparency, and ethical practices. We prioritize integrity in all our interactions.',
    },
    {
        icon: icon_03,
        title: 'Safety',
        text: 'The safety of our clients, employees, and the communities we serve is non-negotiable. We adhere to the strictest safety standards and protocols.',
    },
    {
        icon: icon_04,
        title: 'Client-Centric',
        text: 'We put our clients at the center of everything we do. Your needs, objectives, and satisfaction guide our actions.',
    },
    {
        icon: icon_05,
        title: 'Innovation',
        text: 'We embrace innovation and stay at the forefront of industry advancements to provide cutting-edge solutions to our clients.',
    },
    {
        icon: icon_06,
        title: 'Collaboration',
        text: 'We believe in the power of teamwork and collaboration, both within our organization and with our clients, to achieve the best results.',
    },
    {
        icon: icon_07,
        title: 'Environmental Responsibility',
        text: 'We are committed to minimizing our environmental footprint and promoting sustainable practices in our work.',
    },
    {
        icon: icon_08,
        title: 'Continuous Improvement',
        text: 'We continuously seek opportunities to learn, grow, and improve our services, staying adaptable and responsive to evolving industry trends and client needs.',
    },
];

const Vision = () => {
    const [ current, setCurrent ] = useState(0);
    const [ swiper, setSwiper ] = useState({});
    return (
        <section className="mt container">
            <div className={classes.vision}>
                <h2 className={[classes.title, 'font-48'].join(' ')}>
                    Vision<br/>Mission & Values
                </h2>
                <div className={classes.content}>
                    <div className={classes.blocks}>
                        <div className={classes.block}>
                            <h4 className={classes.blockTitle}>
                                Vision
                            </h4>
                            <p className={[classes.blockText, 'font-16'].join(' ')}>
                                Our company's aim at Ard Altaamir is to be the top provider of building repair and maintenance services, establishing standards for quality, innovation, and customer happiness. For our clients, we strive to design buildings that are safer, more effective, and visually beautiful, improving both their quality of life and company operations.
                            </p>
                        </div>
                        <div className={classes.block}>
                            <h4 className={classes.blockTitle}>
                                Mission
                            </h4>
                            <p className={[classes.blockText, 'font-16'].join(' ')}>
                                Our goal is to provide superior repair and maintenance services for all types of buildings by fusing highly skilled craftsmanship with state-of-the-art technology. Every structure we work on will have its functioning, appearance, and safety preserved and improved. We aim to go above and beyond client expectations and create enduring connections built on dependability and trust.
                            </p>
                        </div>
                    </div>
                    <div className={classes.swiperWrap}>
                        <h4 className={classes.blockTitle}>
                            Values
                        </h4>
                        <div className={classes.swiperContainer}>
                            <Swiper
                                modules={[Grid, Navigation]}
                                className="aboutVisionSwiper no-select"
                                spaceBetween={20}
                                slidesPerView={1}
                                grid={{
                                  rows: 1,
                                  cols: 1,
                                  fill: "row",
                                }}
                                grabCursor
                                onInit={(e) => { setSwiper(e) }}
                                onSlideChange={(e) => setCurrent(e.activeIndex)}
                                breakpoints={{
                                    600: {
                                        slidesPerView: 2,   
                                        grid: {
                                            rows: 2,
                                            cols: 2,
                                            fill: "row",
                                        },
                                    }
                                }}
                            >
                                {cards.map((card, index) =>
                                    <SwiperSlide key={index} className="aboutVisionSwiperSlide">
                                        <div className={classes.card}>
                                            <img className={classes.cardIcon} src={card.icon} alt=''/>
                                            <h4 className={classes.cardTitle}>
                                                {card.title}
                                            </h4>
                                            <p className={[classes.cardText, 'font-16'].join(' ')}>
                                                {card.text}
                                            </p>
                                        </div>
                                    </SwiperSlide>
                                )}
                            </Swiper>
                        </div>
                        <div className={classes.navigation}>
                            <div 
                                className={`${classes.arrow} ${current === 0 && classes.arrowInactive}`}
                                onClick={() => swiper.slidePrev()} 
                            >
                                <img src={arrow} alt=''/>
                            </div>
                            <div
                                className={`${classes.arrow} ${current === 2 && classes.arrowInactive}`}
                                onClick={() => swiper.slideNext()} 
                            >
                                <img src={arrow} alt=''/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Vision;
