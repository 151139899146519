import React, { useEffect } from "react";
import classes from './Footer.module.scss';
import ContactInfo from "../ContactInfo/ContactInfo";
import Button from '../Button/Button';
import { gsap } from "gsap";
import ScrollTrigger from "gsap/src/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const Footer = () => {
    useEffect(() => {
        gsap.to('#footerBody', {
            "--footer-bg-y": 0,
            duration: 1.2,
            ease: "power2.out",
            scrollTrigger: {
                trigger: '#footerBody',
                start: "bottom-=200px bottom",
            }
        });
    }, []);
    return (
        <footer id="footerBody" className={classes.footerWrap}>
            <div className="container">
                <div className={classes.footer}>
                    <div className={classes.col}>
                        <div className={classes.contactUs}>
                            <h2 className={[classes.title, 'font-48'].join(' ')}>
                                Contact Us Today
                            </h2>
                            <p className={[classes.text, 'font-16'].join(' ')}>
                                Learn about the Ard Altaamir advantage and consider us your reliable partner for construction repairs. For a free consultation, get in touch with us right away. Let us work with you to maintain and improve the appearance and usability of your structures.
                            </p>
                            <h4 className={[classes.subtext, 'font-20'].join(' ')}>
                                Choose Ard Altaamir for all your building repair needs. Your satisfaction is our mission!
                            </h4>
                            <div className={classes.btn}>
                                <Button>
                                    Get in touch
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.col}>
                        <div className={classes.contactInfoWrap}>
                            <ContactInfo />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
