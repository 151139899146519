import React, { useEffect } from "react";
import classes from './WorkMaint.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/HomePage/WorkMaint/image.png';
import image_mob from '../../../assets/img/HomePage/WorkMaint/image_mob.png';
import { gsap } from "gsap";
import ScrollTrigger from "gsap/src/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const WorkMaint = () => {
    const { t } = useTranslation();
    useEffect(() => {
        gsap.fromTo('#homeWorkMaintImage', {
            y: 100,
        }, {
            y: 0,
            duration: 1.2,
            ease: "power2.out",
            scrollTrigger: {
                trigger: '#homeWorkMaintBody',
                start: "top+=100px bottom",
            }
        });
    }, []);
    return (
        <section className="container">
            <div id="homeWorkMaintBody" className={classes.workMaint}>
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-48'].join(' ')}>
                        Works<br/>and Maintenance
                    </h2>
                    <p className={[classes.text, 'font-16'].join(' ')}>
                        At Ard Altaamir, we believe that every building, regardless of its type or purpose, deserves to reach its full potential. Our passion lies in helping property owners, whether residential, commercial, or industrial, achieve this by providing exceptional works and maintenance services.  
                    </p>
                </div>
                <div id="homeWorkMaintImage" className={classes.imageWrap}>
                    <img src={image} alt=''/>
                </div>
                <img className={classes.imageMob} src={image_mob} alt=''/>
            </div>
        </section>
    );
};

export default WorkMaint;
