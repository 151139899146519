import React, { useEffect } from "react";
import classes from './Banner.module.scss';
import Button from "../../GlobalComponents/Button/Button";
import { gsap } from "gsap";
gsap.config({
    force3D: true,
});

const Banner = () => {
    useEffect(() => {
        gsap.to('#homeBannerTitle', {
            x: 0,
            delay: 1,
            duration: 1,
        });
        gsap.to("#homeBannerTitleWr", {
            "--title-before-var": 0,
            "--title-before-opacity": 1,
            delay: 1,
            duration: 1,
        }, 0);
        gsap.to('#homeBannerSubtitle', {
            x: 0,
            delay: 1,
            duration: 1,
        });
        gsap.to('#homeBannerText', {
            x: 0,
            delay: 1,
            duration: 1,
        });
        gsap.to('#homeBannerBtn', {
            x: 0,
            delay: 1,
            duration: 1,
        });
        if (document.documentElement.clientWidth > 1220) {
            gsap.to('#homeBannerBg', {
                opacity: 1,
                scale: 1,
                delay: 0,
                duration: 3,
            });
        }
    }, []);
    return (
        <section className={classes.bannerWrap}>
            <div className="container">
                <div className={classes.banner}>
                    <div className={classes.content}>
                        <h2 id="homeBannerTitleWr" className={[classes.title, 'font-48 asddsa'].join(' ')}>
                            <div className={classes.titleC}>
                                <div id="homeBannerTitle">
                                    Ard Altaamir:
                                </div>
                            </div>
                        </h2>
                        <h1 className={classes.subtitle}>
                            <div id="homeBannerSubtitle">
                                Premier Partner for Building Repairs
                            </div>
                        </h1>
                        <div className={classes.bottom}>
                            <div className={classes.textWrap}>
                                <div id="homeBannerText" className={[classes.text, 'font-16'].join(' ')}>
                                    <p>
                                        Ready to Elevate Your Building's Potential?
                                    </p>
                                    <p>
                                        <b>
                                            Contact Us Today for Expert Repairs and Maintenance Services!
                                        </b>
                                    </p>
                                </div>
                            </div>
                            <div className={classes.btnWrap}>
                                <div id="homeBannerBtn" className={classes.btn}>
                                    <Button>
                                        Consultation
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="homeBannerBg" className={classes.bg}></div>
        </section>
    );
};

export default Banner;
