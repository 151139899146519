import React, { useState } from "react";
import classes from './Faq.module.scss';

const Faq = ({ tabs }) => {
    const [ current, setCurrent ] = useState(-1);
    const setTab = (value) => {
        if (current === value) {
            setCurrent(-1);
            return;
        }
        setCurrent(value);
        return;
    };
    return (
        <div className={classes.faq}>
            {tabs.map((tab, index) =>
                <div 
                    className={`${classes.tab} ${current === index && classes.tabActive} no-select`}
                    key={index}
                    onClick={() => setTab(index)}
                >
                    <div className={classes.tabHeader}>
                        <div className={classes.tabStatus}></div>
                        <h4 className={[classes.tabTitle, 'font-20'].join(' ')}>
                            {tab.title}
                        </h4>
                    </div>
                    <div className={[classes.tabContent, 'font-16'].join(' ')}>
                        <p>
                            {tab.text}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Faq;
