import React from "react";
import classes from "./Button.module.scss";
import Cookies from 'js-cookie';
import { Link } from "react-router-dom";

const Button = ({ children, route }) => {
  const lang = Cookies.get('i18next');
  const newRoute = `/${lang}/${route ?? 'contact'}`;
  return (
    <Link 
      to={newRoute} 
      className={`${classes.button}`}
    >
      <span>{children}</span>
    </Link>
  );
};

export default Button;
