import React from "react";

import Faq from "../Faq/Faq";
import classes from "./ComprehensiveElectrical.module.scss"
import image_01 from '../../../assets/img/ServicesPage/Blocks/image_03_01.png';
import image_02 from '../../../assets/img/ServicesPage/Blocks/image_03_02.png';

const tabs = [
    {
        title: 'Troubleshooting and Repairs',
        text: 'Our licensed electricians are capable of identifying and fixing a variety of electrical problems. We quickly and effectively fix issues, such as flickering lights and broken outlets.',
    },
    {
        title: 'Appliance and Equipment Wiring',
        text: 'To guarantee that appliances and equipment run securely and effectively, our qualified electricians handle the wiring and installation of those items.',
    },
    {
        title: 'Electrical Panel Services',
        text: 'To make sure electrical panels adhere to safety regulations and offer sufficient power distribution, we examine, update, and repair them.',
    },
    {
        title: 'Wiring Inspections and Repairs',
        text: 'Electrical risks can result from faulty wiring. To guarantee safety and dependability, our professionals carry out complete inspections and repair or replace wiring.',
    },
    {
        title: 'Lighting Solutions',
        text: 'We have the knowledge to design the best lighting solutions for the interior and exterior of your building, whether you require lighting updates, repairs, or new installations.',
    },
    {
        title: 'Generator Installation and Maintenance',
        text: 'To protect the operations of your building during power outages, we provide generator installation and maintenance services.',
    },
];

const ComprehensiveElectrical = () => {
    return (
        <section className={[classes.blockWrap, classes.blockWrap_01].join(' ')}>
            <div className="container mt">
                <div className={classes.block}>
                    <h2 className={[classes.title, 'font-48'].join(' ')}>
                        Comprehensive<br />Electrical Repair Services
                    </h2>
                    <div className={classes.content}>
                        <div className={classes.txt}>
                            <h4 className={[classes.subtitle, 'font-20'].join(' ')}>
                                Powering Your Building's Potential
                            </h4>
                            <p className={[classes.text, 'font-16'].join(' ')}>
                                We at Ard Altaamir are aware that modern structures cannot function without power. Our comprehensive electrical repair services, which are intended to guarantee the security, functionality, and effectiveness of your electrical systems in all kinds of buildings, are a clear example of our dedication to quality.
                            </p>
                        </div>
                        <div className={classes.faqWrap}>
                            <Faq tabs={tabs} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={[classes.imageWrap, classes.imageWrapLeft, classes.imageWrap_02_01].join(' ')}>
                <img src={image_01} alt='' />
            </div>
            <div className={[classes.imageWrap, classes.imageWrapRight, classes.imageWrap_02_02].join(' ')}>
                <img src={image_02} alt='' />
            </div>
            <div className={[classes.mob, ""].join(" ")}>
                <img src={image_01} alt="" />
            </div>

        </section>
    );
};

export default ComprehensiveElectrical;
