import React from "react";
import classes from './UnderBanner.module.scss';
import { useTranslation } from "react-i18next";

const UnderBanner = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.underBanner}>
                <div className={classes.col}>
                    <p className="font-16">
                        We at Ard Altaamir are aware of how critical it is to keep your buildings' structural reliability and visual appeal. Whether you are the owner of a residential property, a commercial building, or an industrial complex, our professional staff is here to offer thorough repair solutions that satisfy your particular requirements.
                    </p>
                </div>
                <div className={classes.col}>
                    <h4 className={[classes.subtitle, 'font-20'].join(' ')}>
                        We retail all types of buildings:
                    </h4>
                    <ul className="font-16">
                        <li>
                            Residential Buildings
                        </li>
                        <li>
                            Institutional Civic Buildings
                        </li>
                        <li>
                            Retail Buildings
                        </li>
                        <li>
                            Religious Buildings
                        </li>
                        <li>
                            Hospitality Buildings 
                        </li>
                        <li>
                            Industrial Buildings  
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default UnderBanner;
