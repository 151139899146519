import React, { useState } from "react";
import classes from './OurServices.module.scss';
import image_01 from '../../../assets/img/HomePage/OurServices/image_01.webp';
import image_02 from '../../../assets/img/HomePage/OurServices/image_02.webp';
import image_03 from '../../../assets/img/HomePage/OurServices/image_03.webp';
import image_04 from '../../../assets/img/HomePage/OurServices/image_04.webp';
import image_05 from '../../../assets/img/HomePage/OurServices/image_05.webp';
import image_06 from '../../../assets/img/HomePage/OurServices/image_06.webp';
import image_07 from '../../../assets/img/HomePage/OurServices/image_07.webp';
import image_mob_01 from '../../../assets/img/HomePage/OurServices/image_mob_01.webp';
import image_mob_02 from '../../../assets/img/HomePage/OurServices/image_mob_02.webp';
import image_mob_03 from '../../../assets/img/HomePage/OurServices/image_mob_03.webp';
import image_mob_04 from '../../../assets/img/HomePage/OurServices/image_mob_04.webp';
import image_mob_05 from '../../../assets/img/HomePage/OurServices/image_mob_05.webp';
import image_mob_06 from '../../../assets/img/HomePage/OurServices/image_mob_06.webp';
import image_mob_07 from '../../../assets/img/HomePage/OurServices/image_mob_07.webp';
import arrow from '../../../assets/img/HomePage/WhyChoose/arrow.png';
import Button from '../../GlobalComponents/Button/Button';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";

const cards = [
    {
        image: image_01,
        image_mob: image_mob_01,
        title: 'Air Conditioning',
        text: 'We install and repair conditioning fast and reliable.',
    },
    {
        image: image_02,
        image_mob: image_mob_02,
        title: 'Exterior Renovations',
        text: 'With our external remodeling services, which include façade restoration and roof repairs, you may improve the curb appeal of your building.',
    },
    {
        image: image_03,
        image_mob: image_mob_03,
        title: 'Interior Refurbishments',
        text: 'With our interior refurbishment options, which range from workplace updates to full home restorations, you may completely transform the inside of your space.',
    },
    {
        image: image_04,
        image_mob: image_mob_04,
        title: 'Plumbing and Electricity',
        text: 'Our team of licensed electricians and plumbers is available to quickly resolve any electrical or plumbing problems.',
    },
    {
        image: image_05,
        image_mob: image_mob_05,
        title: 'Carpentry and Woodwork',
        text: 'Do your wooden constructions need specialized carpentry solutions or repairs? Our carpenters are master craftsmen.',
    },
    {
        image: image_06,
        image_mob: image_mob_06,
        title: 'Masonry and Tiling',
        text: 'To restore walls, floors, and surfaces, we provide professional masonry and tiling services.',
    },
    {
        image: image_07,
        image_mob: image_mob_07,
        title: 'Industrial Repairs',
        text: 'We also provide services for commercial and industrial buildings to guarantee your facilities\' operating effectiveness and safety.',
    },
];

const OurServices = () => {
    const [ current, setCurrent ] = useState(0);
    const [ swiper, setSwiper ] = useState({});
    return (
        <div className="mt container">
            <div className={classes.ourServices}>
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-48'].join(' ')}>
                        Our Services
                    </h2>
                    <p className={[classes.text, 'font-16'].join(' ')}>
                        At Ard Altaamir, we offer a comprehensive suite of building repair services to cater to your diverse needs:
                    </p>
                </div>
                <div className={classes.swiperWrap}>
                    <div className={classes.swiperContainer}>
                        <Swiper
                            modules={[Navigation]}
                            className="homeOurServicesSwiper no-select"
                            spaceBetween={20}
                            slidesPerView={1}
                            grabCursor
                            onInit={(e) => { setSwiper(e) }}
                            onSlideChange={(e) => setCurrent(e.activeIndex)}
                        >
                            {cards.map((card, index) =>
                                <SwiperSlide key={index} className="homeOurServicesSwiperSlide">
                                    <div className={classes.card}>
                                        <div className={classes.cardImage}>
                                            <img className={classes.cardImageDesc} src={card.image} />
                                            <img className={classes.cardImageMob} src={card.image_mob} alt=''/>
                                        </div>
                                        <div className={classes.cardContent}>
                                            <h4 className={[classes.cardTitle, 'font-20'].join(' ')}>
                                                {card.title}
                                            </h4>
                                            <p className={[classes.cardText, 'font-16'].join(' ')}>
                                                {card.text}
                                            </p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>
                    <div className={classes.bottom}>
                        <div className={classes.navigation}>
                            <div 
                                className={`${classes.arrow} ${current === 0 && classes.arrowInactive}`}
                                onClick={() => swiper.slidePrev()} 
                            >
                                <img src={arrow} alt=''/>
                            </div>
                            <div
                                className={`${classes.arrow} ${current === 6 && classes.arrowInactive}`}
                                onClick={() => swiper.slideNext()} 
                            >
                                <img src={arrow} alt=''/>
                            </div>
                        </div>
                        <div className={classes.btn}>
                            <Button route="services">
                                See more
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurServices;
