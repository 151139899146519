import React, { useState } from "react";
import classes from './Advantages.module.scss';
import icon_01 from '../../../assets/img/ServicesPage/Advantages/icon_01.png';
import icon_02 from '../../../assets/img/ServicesPage/Advantages/icon_02.png';
import icon_03 from '../../../assets/img/ServicesPage/Advantages/icon_03.png';
import arrow from '../../../assets/img/HomePage/WhyChoose/arrow.png';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";

const cards = [
    {
        icon: icon_01,
        title: 'Driving down the cost',
        text: 'Type of retail contract selected by the client, the contractor recognizes that the actual retail services cost will never be identical to.',
    },
    {
        icon: icon_02,
        title: 'Satisfied clients',
        text: 'We work hard to satisfy our clients and believe that customer service is what sets us apart.',
    },
    {
        icon: icon_03,
        title: 'Committed to standards',
        text: 'Our Standards and Conduct Policy sets out how we operate and how we expect to treat customers and others with whom we work.',
    },
];

const Advantages = () => {
    const [ current, setCurrent ] = useState(0);
    const [ swiper, setSwiper ] = useState({});
    return (
        <section className="mt container">
            <div className={classes.advantages}>
                <h2 className={[classes.title, 'font-48'].join(' ')}>
                    Advantages<br/>of working with us
                </h2>
                <div className={classes.swiperWrap}>
                    <Swiper
                        modules={[Navigation]}
                        className="homeAdvantagesSwiper no-select"
                        spaceBetween={20}
                        slidesPerView={1}
                        onInit={(e) => { setSwiper(e) }}
                        onSlideChange={(e) => setCurrent(e.activeIndex)}
                        breakpoints={{
                            900: {
                                slidesPerView: 3,
                            },
                        }}
                    >
                        {cards.map((card, index) =>
                            <SwiperSlide key={index} className="homeAdvantagesSwiperSlide">
                                <div className={classes.card}>
                                    <img className={classes.cardIcon} src={card.icon} alt=''/>
                                    <h4 className={[classes.cardTitle, 'font-20'].join(' ')}>
                                        {card.title}
                                    </h4>
                                    <p className={[classes.cardText, 'font-16'].join(' ')}>
                                        {card.text}
                                    </p>
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
                <div className={classes.navigation}>
                    <div 
                        className={`${classes.arrow} ${current === 0 && classes.arrowInactive}`}
                        onClick={() => swiper.slidePrev()} 
                    >
                        <img src={arrow} alt=''/>
                    </div>
                    <div
                        className={`${classes.arrow} ${current === 2 && classes.arrowInactive}`}
                        onClick={() => swiper.slideNext()} 
                    >
                        <img src={arrow} alt=''/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Advantages;
