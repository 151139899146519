import React from "react";
import Header from '../../components/GlobalComponents/Header/Header';
import Banner from "../../components/ContactPage/Banner/Banner";

const ContactPage = () => {
    return (
        <div className="ovf-hidden">
            <Header />
            <Banner />
        </div>
    );
};

export default ContactPage;
