import React, { useEffect } from "react";
import classes from './OurCommitment.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/HomePage/OurCommitment/image_01.png';
import image_02 from '../../../assets/img/HomePage/OurCommitment/image_02.png';
import { gsap } from "gsap";
import ScrollTrigger from "gsap/src/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const OurCommitment = () => {
    const { t } = useTranslation();
    useEffect(() => {
        gsap.fromTo('#ourCommImageWrap_01', {
            y: 200,
        }, {
            y: 0,
            duration: 1.2,
            ease: "power2.out",
            scrollTrigger: {
                trigger: '#ourCommBody',
                start: "top+=200px bottom",
            }
        });
        gsap.fromTo('#ourCommImageWrap_02', {
            y: -50,
        }, {
            y: 0,
            duration: 1.2,
            ease: "power2.out",
            scrollTrigger: {
                trigger: '#ourCommBody',
                start: "top+=200px bottom",
            }
        });
        gsap.timeline().to('#ourCommImage_01', {
            scale: 1.1,
            scrollTrigger: {
                trigger: "#ourCommBody",
                start: "top-=100px top",
                end: "+=500px",
                scrub: 1,
            }
        });
        gsap.timeline().to('#ourCommImage_02', {
            scale: 1.1,
            scrollTrigger: {
                trigger: "#ourCommBody",
                start: "top-=100px top",
                end: "+=500px",
                scrub: 1,
            }
        });
    }, []);
    return (
        <section className="mt container">
            <div id="ourCommBody" className={classes.ourCommitment}>
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-48'].join(' ')}>
                        <span>
                            Our Commitment
                        </span>
                        <span>
                            to Excellence
                        </span>
                    </h2>
                    <div className={[classes.text, 'font-16'].join(' ')}>
                        <p>
                            Ard Altaamir has accumulated a wealth of knowledge in the field and we have established a reputation for competence in building repairs. We take pride in our steadfast dedication to providing top-notch services that not only meet but also beyond your expectations. Our dedicated staff members are outfitted with the know-how, abilities, and cutting-edge equipment necessary to handle a variety of building repair and maintenance projects.
                        </p>
                        <p>
                            We are committed to providing first-rate services that not only live up to but also surpass your expectations!
                        </p>
                    </div>
                </div>
                <div className={classes.images}>
                    <div id="ourCommImageWrap_01" className={[classes.imageWrap, classes.imageWrap_01].join(' ')}>
                        <img id="ourCommImage_01" src={image_01} alt=''/>
                    </div>
                    <div id="ourCommImageWrap_02" className={[classes.imageWrap, classes.imageWrap_02].join(' ')}>
                        <img id="ourCommImage_02" src={image_02} alt=''/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurCommitment;
