import React from "react";
import classes from './Banner.module.scss';
import Button from "../../GlobalComponents/Button/Button";

const Banner = () => {
    return (
        <section className={classes.bannerWrap}>
            <div className="container">
                <div className={classes.banner}>
                    <div className={classes.content}>
                        <h2 className={[classes.title, 'font-48'].join(' ')}>
                            Discover Ard Altaamir:
                        </h2>
                        <h1 className={classes.subtitle}>
                            Your Trusted Partner in Building Repairs
                        </h1>
                        <div className={[classes.text, 'font-16'].join(' ')}>
                            <p>
                                Ard Altaamir has built a solid reputation for competence in the business and has become a household name for repairs and upkeep on all kinds of buildings.
                            </p>
                            <p>
                                We are committed to providing first-rate services that not only live up to but also surpass your expectations.
                            </p>
                            <p>
                                Our team of professionals is equipped with the expertise, modern equipment, and experience necessary to handle a variety of maintenance and repair requirements.
                            </p>
                        </div>
                        <div className={classes.btn}>
                            <Button>
                                Consultation
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.bg}></div>
        </section>
    );
};

export default Banner;
