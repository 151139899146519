import React, { useEffect, useState } from "react";
import classes from './WhyChoose.module.scss';
import image_01 from '../../../assets/img/HomePage/WhyChoose/image_01.png';
import image_02 from '../../../assets/img/HomePage/WhyChoose/image_02.png';
import image_03 from '../../../assets/img/HomePage/WhyChoose/image_03.png';
import image_04 from '../../../assets/img/HomePage/WhyChoose/image_04.png';
import image_05 from '../../../assets/img/HomePage/WhyChoose/image_05.png';
import arrow from '../../../assets/img/HomePage/WhyChoose/arrow.png';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";

const cards = [
    {
        image: image_01,
        title: 'Quality Assurance',
        text: 'We uphold the highest standards of quality in every project, ensuring lasting results and client satisfaction.',
    },
    {
        image: image_02,
        title: 'Transparent Pricing',
        text: 'We provide clear and competitive pricing, with no hidden costs.',
    },
    {
        image: image_03,
        title: 'Licensed and Insured',
        text: 'Our team consists of licensed and insured professionals, offering peace of mind.',
    },
    {
        image: image_04,
        title: 'Reliability',
        text: 'Count on us to deliver punctual service and adhere to project timelines.',
    },
    {
        image: image_05,
        title: 'Customer-Centric Approach',
        text: 'Your needs are at the forefront of our services. We work closely with you to understand your objectives and deliver accordingly.',
    },
];

const WhyChoose = () => {
    const [ current, setCurrent ] = useState(0);
    const [ swiper, setSwiper ] = useState({});
    const setPrev = () => {
        if (document.documentElement.clientWidth <= 600) {
            swiper.slidePrev();
            return;
        }
        if (current === 0) {
            return;
        }
        return setCurrent((value) => --value);
    };
    const setNext = () => {
        if (document.documentElement.clientWidth <= 600) {
            swiper.slideNext();
            return;
        }
        if (current === 4) {
            return;
        }
        return setCurrent((value) => ++value);
    };
    return (
        <div className="mt container">
            <div className={classes.whyChoose}>
                <h2 className={[classes.title, 'font-48'].join(' ')}>
                    Why Choose Ard Altaamir?
                </h2>
                <div className={classes.content}>
                    <div className={classes.cards}>
                        {cards.map((card, index) =>
                            <div 
                                key={index} 
                                className={`${classes.card} ${current === index && classes.cardActive}`}
                            >
                                <div className={classes.cardContent}>
                                    <h4 className={[classes.cardTitle, 'font-20'].join(' ')}>
                                        {card.title}
                                    </h4>
                                    <p className={[classes.cardText, 'font-16'].join(' ')}>
                                        {card.text}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={classes.swiperWrap}>
                        <Swiper
                            modules={[Navigation]}
                            className="homeWhyChooseSwiper no-select"
                            spaceBetween={150}
                            slidesPerView={1}
                            onInit={(e) => { setSwiper(e) }}
                            onSlideChange={(e) => setCurrent(e.activeIndex)}
                        >
                            {cards.map((card, index) =>
                                <SwiperSlide key={index} className="homeWhyChooseSwiperSlide">
                                    <div className={classes.card}>
                                        <h4 className={[classes.cardTitle, 'font-20'].join(' ')}>
                                            {card.title}
                                        </h4>
                                        <p className={[classes.cardText, 'font-16'].join(' ')}>
                                            {card.text}
                                        </p>
                                    </div>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>
                    <div className={classes.bg}>
                        {cards.map((card, index) =>
                            <img
                                className={`${classes.bgImg} ${current === index && classes.bgImgActive}`} 
                                key={index}
                                src={card.image} 
                                alt=''
                            />
                        )}
                    </div>
                    <div className={classes.navigation}>
                        <div 
                            className={`${classes.arrow} ${current === 0 && classes.arrowInactive}`}
                            onClick={setPrev} 
                        >
                            <img src={arrow} alt=''/>
                        </div>
                        <div
                            className={`${classes.arrow} ${current === 4 && classes.arrowInactive}`}
                            onClick={setNext} 
                        >
                            <img src={arrow} alt=''/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhyChoose;
