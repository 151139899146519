import React from "react";
import Header from '../../components/GlobalComponents/Header/Header';
import Banner from "../../components/ServicesPage/Banner/Banner";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Advantages from "../../components/ServicesPage/Advantages/Advantages";
import ExpertBlumning from "../../components/ServicesPage/ExpertBlumning/ExpertBlumning";
import PremiumAir from "../../components/ServicesPage/PremiumAir/PremiumAir";
import ComprehensiveElectrical from "../../components/ServicesPage/ComprehensiveElectrical/ComprehensiveElectrical";
import MasterfulMasonry from "../../components/ServicesPage/MasterfulMasonry/MasterfulMasonry";

const ServicesPage = () => {
    return (
        <div className="ovf-hidden">
            <Header />
            <Banner />
            <ExpertBlumning />
            <PremiumAir />
            <ComprehensiveElectrical />
            {/* <div className="pt">
                bottom
            </div> */}
            <MasterfulMasonry />
            <Advantages />
            <Footer />
        </div>
    );
};

export default ServicesPage;
