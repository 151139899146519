import React from "react";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import UnderBanner from "../../components/HomePage/UnderBanner/UnderBanner";
import OurCommitment from "../../components/HomePage/OurCommitment/OurCommitment";
import WorkMaint from "../../components/HomePage/WorkMaint/WorkMaint";
import WhyChoose from "../../components/HomePage/WhyChoose/WhyChoose";
import OurServices from "../../components/HomePage/OurServices/OurServices";
import Banner from "../../components/HomePage/Banner/Banner";

const HomePage = () => {
  return (
    <div className="ovf-hidden">
      <Header />
      <Banner />
      <UnderBanner />
      <OurCommitment />
      <WorkMaint />
      <WhyChoose />
      <OurServices />
      <Footer />
    </div>
  );
};

export default HomePage;
