import HomePage from '../pages/HomePage/HomePage';
import ServicesPage from '../pages/ServicesPage/ServicesPage';
import AboutPage from '../pages/AboutPage/AboutPage';
import ContactPage from '../pages/ContactPage/ContactPage';

export const routes = [
    { path: '/', element: HomePage },
    { path: '/services', element: ServicesPage },
    { path: '/about', element: AboutPage },
    { path: '/contact', element: ContactPage },
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/services', element: ServicesPage },
    { path: '/:lang/about', element: AboutPage },
    { path: '/:lang/contact', element: ContactPage },
];

export const headerRoutes = [
    { id: 1, path: 'services', text: 'Services' },
    { id: 2, path: 'about', text: 'About Us' },
    { id: 3, path: 'contact', text: 'Contact Us' },
];
