import React from "react";
import classes from './ExpertBlumning.module.scss';
import Faq from "../Faq/Faq";
import image_01 from '../../../assets/img/ServicesPage/Blocks/image_01_01.png';
import image_02 from '../../../assets/img/ServicesPage/Blocks/image_01_02.png';

const tabs = [
    {
        title: 'Repairs and Maintenance',
        text: 'From leaks and clogs to pipe repairs and replacements, our knowledgeable plumbers are competent in identifying and resolving a wide range of plumbing problems. We do regular maintenance to maintain your plumbing in top shape and avert expensive emergencies.',
    },
    {
        title: 'Emergency Plumbing',
        text: 'You may rely on our team of emergency plumbers to respond right away if a plumbing emergency arises. When you need us the most, we respond quickly to address urgent problems and reduce downtime.',
    },
    {
        title: 'New Installations',
        text: 'We provide professional plumbing installs whether you\'re building a new structure or remodeling an existing one. Our staff makes sure that your plumbing is planned and set up to fit the particular requirements of your building.',
    },
    {
        title: 'Fixture and Appliance Installation',
        text: 'Sinks, toilets, faucets, water heaters, and other fixtures and appliances are among the ones we install. Our meticulous labor ensures utility and effectiveness.',
    },
    {
        title: 'Drain Cleaning and Unclogging',
        text: 'Our cutting-edge tools can easily clear even the most stubborn clogs and slow drains. We swiftly and effectively remove obstructions to restore adequate drainage.',
    },
    {
        title: 'Pipe Inspection and Repair',
        text: 'With the help of our cutting-edge pipe inspection technology, we can find concealed problems without using force. In order to avoid leaks and water damage, we fix or replace pipes as necessary.',
    },
];

const ExpertBlumning = () => {
    return (
        <section className={[classes.blockWrap, classes.blockWrap_01].join(' ')}>
            <div className="container mt">
                <div className={classes.block}>
                    <h2 className={[classes.title, 'font-48'].join(' ')}>
                        Expert<br/>Plumbing Services
                    </h2>
                    <div className={classes.content}>
                        <div className={classes.txt}>
                            <h4 className={[classes.subtitle, 'font-20'].join(' ')}>
                                to Keep Your Building Flowing Smoothly
                            </h4>
                            <p className={[classes.text, 'font-16'].join(' ')}>
                                We at Ard Altaamir are aware that a working plumbing system is essential to the survival of any structure.
                            </p>
                        </div>
                        <div className={classes.faqWrap}>
                            <Faq tabs={tabs} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={[classes.imageWrap, classes.imageWrapLeft, classes.imageWrap_01_01].join(' ')}>
                <img src={image_01} alt=''/>
            </div>
            <div className={[classes.imageWrap, classes.imageWrapRight, classes.imageWrap_01_02].join(' ')}>
                <img src={image_02} alt=''/>
            </div>
        </section>
    );
};

export default ExpertBlumning;
