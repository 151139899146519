import _ from 'lodash';
import React, { useState } from "react";
import classes from './Banner.module.scss';
import axios from 'axios';
import ContactInfo from "../../GlobalComponents/ContactInfo/ContactInfo";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Banner = () => {
    const [ success, setSuccess ] = useState(false);

    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ message, setMessage ] = useState('');
    const [ phone, setPhone ] = useState('');

    const [ nameValid, setNameValid ] = useState(true);
    const [ emailValid, setEmailValid ] = useState(true);
    const [ phoneValid, setPhoneValid ] = useState(true);

    const validateName = () => {
        if (name === '') {
            setNameValid(false);
            return false;
        }
        setNameValid(true);
        return true;
    };
    const validateEmail = () => {
        const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!expression.test(String(email.toLocaleLowerCase()))) {
            setEmailValid(false);
            return false;
        }
        setEmailValid(true);
        return true;
    };
    const validatePhone = () => {
        if (phone.length <= 10) {
            setPhoneValid(false);
            return false;
        }
        setPhoneValid(true);
        return true;
    };
    
    const setDataDefaultState = () => {
        setName('');
        setPhone('');
        setEmail('');
        setMessage('');
        setNameValid(true);
        setPhoneValid(true);
        setEmailValid(true);
    };

    const validateInputData = () => {
        let valid = true;
        const validArr = [];

        validArr.push(validateName());
        validArr.push(validateEmail());
        validArr.push(validatePhone());

        _.map(validArr, (item) => !item && (valid = false));
        return valid;
    };

    const sendMessage = async () => {
        const messageData = {
            name,
            email,
            phone,
            message,
        };
        setDataDefaultState();
        try {
            const data = await axios.post(`https://civbt.xyz/api/bot/loFlTrqIgo`, messageData);
            if (data.status === 200) {
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(false);
                }, 20000);
            } else {
                throw({ message: 'Something went wrong. Try again.' })
            }
        } catch (error) {
            alert(`${error.message}`);
            setSuccess(false);
        }
    };

    const submit = () => {
        if (validateInputData()) {
            sendMessage();
        }
        return;
    };

    return (
        <section className={classes.bannerWrap}>
            <div className="container">
                <div className={classes.banner}>
                    <div className={classes.col}>
                        <div className={classes.contactUs}>
                            <h1 className={[classes.title, 'font-48'].join(' ')}>
                                Contact Us
                            </h1>
                            <p className={[classes.text, 'font-16'].join(' ')}>
                                Ready to Transform Your Building? Let's Get Started!
                                <br/>Fill out the form below to schedule a consultation with our experts. 
                                <br/>Let's build excellence together.
                            </p>
                            <div className={`${success ? classes.success : ''}`}>
                                <div className={classes.popup}>
                                    <div className={classes.popupImg}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="23" viewBox="0 0 32 23" fill="none">
                                            <path d="M11.625 22.8431L0.6875 11.9034L3.77844 8.8125L11.625 16.6569L28.2172 0.0625L31.3125 3.15781L11.625 22.8431Z" fill="#D71924"/>
                                        </svg>
                                    </div>
                                    <p className={[classes.popupText, 'font-17'].join(' ')}>
                                        Thank you! The manager will contact you shortly.
                                    </p>
                                </div>
                                <div className={classes.form}>
                                        <div className={classes.inputWrap}>
                                            <input
                                                className={`${classes.input} ${!nameValid ? classes.error : ''}`}
                                                type="text"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                placeholder="Name"
                                            />
                                        </div>
                                        <div className={classes.inputWrap}>
                                            <input
                                                className={`${classes.input} ${!emailValid ? classes.error : ''}`}
                                                type="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                placeholder="E-mail"
                                            />
                                        </div>
                                        <div className={classes.inputWrap}>
                                            <PhoneInput
                                                enableSearch
                                                disableSearchIcon
                                                country={"ae"}
                                                preferredCountries={["ae"]}
                                                onChange={(phone) => setPhone(phone)}
                                                className={`${classes.phone}`}
                                                containerClass={classes.containerClass}
                                                inputClass={`${classes.inputClass} ${!phoneValid && classes.error}`}
                                                buttonClass={classes.buttonClass}
                                                dropdownClass={classes.dropdownClass}
                                                searchClass={classes.searchClass}
                                            />
                                        </div>
                                        <div className={classes.inputWrap}>
                                            <input
                                                className={classes.input}
                                                type="text"
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                                placeholder="Message"
                                            />
                                        </div>
                                </div>
                                <div className={classes.btnWrap}>
                                        <div 
                                            className={classes.button}
                                            onClick={submit}
                                        >
                                            <span>
                                                Get in touch
                                            </span>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.col}>
                        <div className={classes.contactInfoWrap}>
                            <ContactInfo />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
