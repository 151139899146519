import React from "react";

import Faq from "../Faq/Faq";
import classes from "./PremiumAir.module.scss"
import image_01 from '../../../assets/img/ServicesPage/Blocks/image_02_01.png';
import image_02 from '../../../assets/img/ServicesPage/Blocks/image_02_02.png';

const tabs = [
    {
        title: 'Repairs and Maintenance',
        text: 'Our team of skilled professionals is prepared to quickly diagnose and fix a variety of air conditioning problems. We provide regular maintenance to keep your systems operating at their best and lower the possibility of unplanned failures.',
    },
    {
        title: 'Emergency Plumbing',
        text: 'Our emergency response crew is available around-the-clock if your air conditioner breaks down unexpectedly. To minimize disturbances and swiftly restore comfort, we act.',
    },
    {
        title: 'New Installations',
        text: 'We offer professional air conditioning system installs, whether you\'re constructing a new building or remodeling an existing one.We create and install solutions that are specifically matched to the requirements of your building.',
    },
    {
        title: 'Ductwork Inspection and Cleaning',
        text: 'Ductwork that has been properly maintained is essential for effective cooling and heating. To guarantee ideal circulation and air quality, we provide duct inspection and cleaning services.',
    },
    {
        title: 'Thermostat Installation and Calibration',
        text: 'For accurate temperature regulation, a properly calibrated thermostat is necessary. Thermostats are installed and calibrated by us to ensure precise and energy-efficient performance.',
    },
    {
        title: 'Air Quality Solutions',
        text: 'To improve indoor air quality and make it more pleasant and healthy, we provide humidification and filtration systems.',
    },
];

const PremiumAir = () => {
    return (
        <section className={[classes.blockWrap, classes.blockWrap_01].join(' ')}>
            <div className="container mt">
                <div className={classes.block}>
                    <h2 className={[classes.title, 'font-48'].join(' ')}>
                        Premium<br />Air Conditioning Services
                    </h2>
                    <div className={classes.content}>
                        <div className={classes.txt}>
                            <h4 className={[classes.subtitle, 'font-20'].join(' ')}>
                                Your Comfort, Our Commitment
                            </h4>
                            <p className={[classes.text, 'font-16'].join(' ')}>
                                At Ard Altaamir, we are aware of how crucial a working air conditioning system is to the convenience and efficiency of your building.
                            </p>
                        </div>
                        <div className={classes.faqWrap}>
                            <Faq tabs={tabs} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={[classes.imageWrap, classes.imageWrapLeft, classes.imageWrap_02_01].join(' ')}>
                <img src={image_01} alt='' />
            </div>
            <div className={[classes.imageWrap, classes.imageWrapRight, classes.imageWrap_02_02].join(' ')}>
                <img src={image_02} alt='' />
            </div>
            <div className={[classes.mob, ""].join(" ")}>
                <img src={image_01} alt="" />
            </div>

        </section>
    );
};

export default PremiumAir;
