import React from "react";
import Header from '../../components/GlobalComponents/Header/Header';
import Banner from "../../components/AboutPage/Banner/Banner";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Vision from "../../components/AboutPage/Vision/Vision";
import History from "../../components/AboutPage/History/History";

const AboutPage = () => {
    return (
        <div className="ovf-hidden">
            <Header />
            <Banner />
            <Vision />
            <History />
            <Footer />
        </div>
    );
};

export default AboutPage;
