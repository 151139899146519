import React, { useEffect, useState } from "react";
import classes from './History.module.scss';
import arrow from '../../../assets/img/HomePage/WhyChoose/arrow.png';
import image_01 from '../../../assets/img/AboutPage/History/image_01.png';
import image_02 from '../../../assets/img/AboutPage/History/image_02.png';
import image_03 from '../../../assets/img/AboutPage/History/image_03.png';
import image_04 from '../../../assets/img/AboutPage/History/image_04.png';
import image_05 from '../../../assets/img/AboutPage/History/image_05.png';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";

const cards = [
    {
        image: image_01,
        title: 'Our History',
        text: 'Building Excellence Since 2017 Ard Altaamir has a rich and storied history of providing top-notch repair and maintenance services to all types of buildings. Founded in 2017, our journey has been marked by a commitment to excellence, unwavering dedication, and a passion for elevating the quality of living and working spaces.',
    },
    {
        image: image_02,
        title: 'The Early Years',
        text: 'In our early years, we started as a small team of skilled craftsmen and technicians, focusing on local residential repair projects. Our commitment to providing top-notch work and unrivaled customer service quickly established a solid reputation for dependability and credibility..',
    },
    {
        image: image_03,
        title: 'Expanding Our Horizons',
        text: 'We were aware of the need to increase our knowledge and capabilities as the demand for our services increased. As a result, we expanded into commercial and industrial building repairs to serve a wider clientele. Our success was largely attributed to our capacity to quickly adjust to new situations and offer creative answers.',
    },
    {
        image: image_04,
        title: 'Becoming a Trusted Partner',
        text: 'Ard Altaamir has developed into a one-stop shop for all building maintenance and repair requirements throughout the years. We take pleasure in being a dependable partner for business owners, facility managers, and property owners, providing a wide range of services to maintain and improve the structural integrity and aesthetic appeal of their structures.',
    },
    {
        image: image_05,
        title: 'Today and Beyond',
        text: 'Today, Ard Altaamir is regarded as the pinnacle of the building repair sector. We continue to offer top-notch repair and maintenance services for all kinds of buildings thanks to a team of licensed personnel, cutting-edge technology, and a dedication to customer satisfaction. We are committed to our core principles of excellence, integrity, safety, and innovation as we move to the future. Our history has influenced us, but our vision drives us ahead as we work to surpass clients\' expectations and establish new industry benchmarks.',
    },
];

const History = () => {
    const [ current, setCurrent ] = useState(0);
    const [ swiper, setSwiper ] = useState({});
    const setPrev = () => {
        if (document.documentElement.clientWidth <= 600) {
            swiper.slidePrev();
            return;
        }
        if (current === 0) {
            return;
        }
        return setCurrent((value) => --value);
    };
    const setNext = () => {
        if (document.documentElement.clientWidth <= 600) {
            swiper.slideNext();
            return;
        }
        if (current === 4) {
            return;
        }
        return setCurrent((value) => ++value);
    };
    return (
        <section className="mt container">
            <div className={classes.history}>
                <h2 className={[classes.title, 'font-48'].join(' ')}>
                    History
                </h2>
                <div className={classes.content}>
                    <div className={classes.cards}>
                        {cards.map((card, index) =>
                            <div 
                                key={index} 
                                className={`${classes.card} ${current === index && classes.cardActive}`}
                            >
                                <div className={classes.cardContent}>
                                    <h4 className={[classes.cardTitle, 'font-20'].join(' ')}>
                                        {card.title}
                                    </h4>
                                    <p className={[classes.cardText, 'font-16'].join(' ')}>
                                        {card.text}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={classes.swiperWrap}>
                        <Swiper
                            modules={[Navigation]}
                            className="homeWhyChooseSwiper no-select"
                            spaceBetween={150}
                            slidesPerView={1}
                            onInit={(e) => { setSwiper(e) }}
                            onSlideChange={(e) => setCurrent(e.activeIndex)}
                        >
                            {cards.map((card, index) =>
                                <SwiperSlide key={index} className="homeWhyChooseSwiperSlide">
                                    <div className={classes.card}>
                                        <h4 className={[classes.cardTitle, 'font-20'].join(' ')}>
                                            {card.title}
                                        </h4>
                                        <p className={[classes.cardText, 'font-16'].join(' ')}>
                                            {card.text}
                                        </p>
                                    </div>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>
                    <div className={classes.bg}>
                        {cards.map((card, index) =>
                            <img
                                className={`${classes.bgImg} ${current === index && classes.bgImgActive}`} 
                                key={index}
                                src={card.image} 
                                alt=''
                            />
                        )}
                    </div>
                    <div className={classes.pagination}>
                        <div className={classes.paginationNum}>
                            <span>0{current+1}</span> / 05
                        </div>
                        <div className={classes.paginationLine}>
                            <div 
                                className={classes.paginationLineRed}
                                style={{width: `${(current+1) * 20}%`}}
                            ></div>
                        </div>
                    </div>
                    <div className={classes.navigation}>
                        <div 
                            className={`${classes.arrow} ${current === 0 && classes.arrowInactive}`}
                            onClick={setPrev} 
                        >
                            <img src={arrow} alt=''/>
                        </div>
                        <div
                            className={`${classes.arrow} ${current === 4 && classes.arrowInactive}`}
                            onClick={setNext} 
                        >
                            <img src={arrow} alt=''/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default History;
