import React from "react";
import classes from './ContactInfo.module.scss';
import phone from '../../../assets/img/Footer/phone.svg';
import mark from '../../../assets/img/Footer/mark.svg';

const ContactInfo = () => {
    return (
        <section className={classes.contactInfo}>
            <h2 className={[classes.title, 'font-48'].join(' ')}>
                <span>
                    Contact Info
                </span>
            </h2>
            <div className={classes.content}>
                <div className={classes.contactUs}>
                    <p>
                        Contact us for any information
                    </p>
                    <div className={classes.contacts}>
                        <div className={classes.row}>
                            <img src={phone} alt=''/>
                            <a href="tel:+971523252486">
                                +971523252486
                            </a>
                        </div>
                        <div className={classes.row}>
                            <img src={mark} alt=''/>
                            <div>
                                Building 7, business Bay, Bay Square, Dubai, UAE
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.hours}>
                    <p>
                        Business Hours
                    </p>
                    <ul>
                        <li>
                            We work 6 days a week
                        </li>
                        <li>
                            Sun - Wed: 08:00 am - 05.30 pm
                        </li>
                        <li>
                            Thur - Fr: 08:00 am - 02:00 pm
                        </li>
                        <li>
                            Saturday: 08:00 am - 05:30 pm
                        </li>
                        <li>
                            Every Sunday is holiday
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default ContactInfo;
